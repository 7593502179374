<template>
  <div :class="mainClass">
    <input
      :id="id"
      :checked="isChecked"
      :type="type"
      :class="inputClass"
      v-bind="$attrs"
      v-on="listeners"
      @input="onChange"
    />
    <label :for="id" :class="labelClass">
      <slot>
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: [String, Boolean],
      default: '',
    },
    fullLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Label',
    },
    size: {
      type: String,
      required: false,
      default: 'small',
      validator(size) {
        return ['small', 'large'].includes(size);
      },
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        return ['checkbox', 'radio'].includes(type);
      },
    },
  },
  computed: {
    mainClass() {
      return `toggle-${this.type}`;
    },
    inputClass() {
      return `${this.mainClass}__input`;
    },
    labelClass() {
      return {
        [`${this.mainClass}__label`]: true,
        [`${this.mainClass}__label--${this.size}`]: true,
        [`${this.mainClass}__label--full`]: this.fullLabel,
      };
    },
    id() {
      return this.$attrs.id || '';
    },
    isChecked() {
      return this.checked === this.$attrs.value;
    },
    listeners() {
      const { change, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  methods: {
    onChange($event) {
      const value = this.$attrs.value
        ? this.$attrs.value
        : $event.target.checked;
      this.$emit('change', value);
    },
  },
};
</script>
