<template>
  <GoogleMap
    :zoom="zoom"
    :min-zoom="10"
    :max-zoom="20"
    :center="center"
    v-on="$listeners"
  >
    <GoogleMarker
      v-for="{
        lat,
        lng,
        title,
        id,
        slug,
        image,
        isActive,
        orientation,
      } in markers"
      :id="id"
      :key="id"
      :lat="lat"
      :lng="lng"
      :title="title"
      :slug="slug"
      :image="image"
      :is-active="isActive"
      :orientation="orientation"
      v-on="$listeners"
    />
  </GoogleMap>
</template>

<script>
import GoogleMap from '../map/map.vue'; // Cannot use a Map name it's a reserved tag.
import GoogleMarker from '../marker/marker.vue'; // Cannot use a Marker name it's a reserved tag.
import mapProps from '../map/mapProps';

export default {
  name: 'MapWithMarkers',
  components: {
    GoogleMap,
    GoogleMarker,
  },
  props: {
    ...mapProps,
    markers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
