<template>
  <Categories :categories="categories" :loading="loading" />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Categories from '../categories/categories.vue';

export default {
  name: 'FeaturedCategories',
  components: {
    Categories,
  },
  computed: {
    ...mapState('categories', ['categories', 'loading']),
  },
  created() {
    this.fetchFeaturedCategories();
  },
  methods: {
    ...mapActions('categories', ['fetchFeaturedCategories']),
  },
};
</script>
