<template>
  <section
    :class="{
      'search-results--grid-layout': isGridLayout,
      'search-results--map-layout': isMapLayout,
      'search-results--has-buttons': hasAtLeastOneFilter,
      'search-results--grid-layout-has-pagination': gridLayoutHasPagination,
      'search-results--map-layout-has-pagination': mapLayoutHasPagination,
    }"
    class="search-results"
  >
    <div class="search-results__menu search-results__menu--filters">
      <div class="search-results__filters">
        <div
          :class="{
            'search-filters--active': searchFiltersMobileActive,
          }"
          class="search-filters"
        >
          <p
            ref="searchFiltersLabel"
            class="search-filters__label"
            @click="filterLabelOnClick"
          >
            Filtruj
            <span
              ref="searchFiltersLabelText"
              class="search-filters__text-xs-hidden"
            >
              wyszukiwanie
            </span>
            <span class="search-filters__colon">:</span>
          </p>

          <ul
            v-click-outside="searchFilterListOnClickOutside"
            class="search-filters__list"
          >
            <li
              v-for="filter in filters"
              :key="filter.name"
              class="search-filters__item"
            >
              <SearchFilter
                v-if="filter.enabled && filter.showFilterUI"
                :checked="filter.selectedTerms"
                :has-search="filter.hasSearch"
                :terms="filter.terms"
                :taxonomy="filter.taxonomy"
                :name="filter.name"
                :is-mobile-active="filter.taxonomy === activeMobileFilter"
                @init="searchFilterOnInit"
                @change="searchFilterOnChange"
                @label-click="searchFilterLabelOnClick"
              />
            </li>
          </ul>
          <!-- /.search-filters__list -->
        </div>
        <!-- /.search-filters -->
      </div>
      <!-- /.search-results__filters -->

      <div class="search-results__buttons">
        <div class="layout-toggle">
          <p class="layout-toggle__label">
            Sposób wyświetlania &colon;
          </p>
          <button
            :class="{
              'layout-toggle__button--active': isMapLayout,
            }"
            class="layout-toggle__button layout-toggle__button--map"
            @click="mapToggleOnClick"
          >
            Mapa
          </button>
          <button
            :class="{
              'layout-toggle__button--active': isGridLayout,
            }"
            class="layout-toggle__button layout-toggle__button--grid"
            @click="gridToggleOnClick"
          >
            Lista
          </button>
        </div>
      </div>
      <!-- /.search-results__buttons -->
    </div>
    <!-- /.search-results__menu -->

    <div
      v-if="hasAtLeastOneFilter"
      class="search-results__menu search-results__menu--buttons"
    >
      <ul class="active-filters">
        <template v-for="{ selectedTerms, name, taxonomy, enabled } in filters">
          <template v-if="enabled">
            <li
              v-for="term in selectedTerms"
              :key="`${taxonomy}-${term}`"
              class="active-filters__item"
            >
              <button
                aria-label="Usuń"
                class="active-filters__delete"
                @click="removeFilter(taxonomy, term)"
              >
                &times;
              </button>
              <span class="active-filters__category"> {{ name }}: </span>
              <span class="active-filters__name">
                <template v-if="taxonomy === 'all'">
                  {{ $route.query.searchPhrase }}
                </template>
                <template v-else>
                  {{ getTermName(taxonomy, term) }}
                </template>
              </span>
            </li>
          </template>
        </template>
      </ul>
    </div>
    <!-- /.search-results-toolbar -->

    <div class="search-results__container">
      <div v-if="isMapLayout" class="search-results__map">
        <MapWithMarkers
          :markers="markers"
          :zoom="zoom"
          :min-zoom="10"
          :max-zoom="18"
          :center="center"
          @mapDragend="cordsOnUpdate"
          @mapZoomChanged="cordsOnUpdate"
          @markerAdded="markerOnAdd"
          @markerDestroyed="markerOnDestroy"
          @markerClick="markerOnClick"
          @markerMouseover="markerOnMouseover"
          @markerMouseout="markerOnMouseout"
        />
      </div>

      <section
        :class="{
          'search-results__content--loading': loading,
        }"
        class="search-results__content"
      >
        <header class="search-results__header">
          <h1 v-if="total" class="search-results__count">
            Znaleziono <strong>{{ total }}</strong> zdjęć
          </h1>

          <div class="search-results__toolbar">
            <Toolbar
              :orientation="orientation"
              :panoramic="panoramic"
              :premium="premium"
              :per-page="perPage"
              @change="toolbarOnChange"
            />
          </div>
        </header>

        <div
          :class="{
            'search-results__images-grid--empty':
              products.length === 0 && !loading,
          }"
          class="search-results__images-grid"
        >
          <ImagesGrid
            v-if="products.length > 0 && !loading"
            :images="products"
            :fixed-layout="isGridLayout"
            :compact-mode="isMapLayout"
            @imageMouseEnter="imageOnMouseEnter"
            @imageMouseLeave="imageOnMouseLeave"
          />
          <ImagesGridSkeleton v-if="loading" :fixed-layout="isGridLayout" />
        </div>

        <div v-if="mapLayoutHasPagination" class="search-results__pagination">
          <Pagination
            :current="page"
            :total="total"
            :pages="pages"
            :loading="loading"
            @change="paginationOnChange"
          />
        </div>

        <div v-if="products.length === 0 && !loading" class="no-results">
          <div
            :class="{
              'info-box--simple': isGridLayout,
            }"
            class="info-box"
          >
            <p class="info-box__text">
              Brak wyników pasujących do wybranych filtrów.
            </p>

            <div class="info-box__button">
              <router-link
                :to="{
                  name: 'search-results',
                  query: {
                    layout,
                  },
                }"
                class="button button--strong"
              >
                <template v-if="searchTermName">
                  Wróć do wyników wyszukiwania dla frazy:
                  <strong>{{ searchTermName }}</strong>
                </template>
                <template v-else>
                  Wyczyść wszystkie filtry
                </template>
              </router-link>
            </div>
          </div>

          <div v-if="isGridLayout" class="no-results__categories">
            <!-- eslint-disable max-len -->
            <h2
              class="text text--xs-header-3 text--sm-header-2 text--xs-center text--sm-strong text--sm-mt-1 text--sm-mb-2"
            >
              Polecane kategorie
            </h2>

            <FeaturedCategories />
          </div>
        </div>
      </section>
      <!-- /.search-results__content -->
    </div>
    <!-- /.search--results__container -->

    <div v-if="gridLayoutHasPagination" class="search-results__pagination">
      <Pagination
        :current="page"
        :total="total"
        :pages="pages"
        @change="paginationOnChange"
      />
    </div>

    <div v-if="isGridLayout && loading" class="search-results__pagination">
      <div class="container">
        <Rectangle :height="2" />
      </div>
    </div>
  </section>
  <!-- /.search-results -->
</template>

<script>
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import has from 'lodash/has';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { mapActions, mapGetters, mapState } from 'vuex';
import vClickOutside from 'v-click-outside';
import naviMixin from '../../utils/naviMixin';
import getRelLinksByRouteMixin from '../../utils/getRelLinksByRouteMixin';
import ImagesGrid from '../images-grid/images-grid.vue';
import ImagesGridSkeleton from '../images-grid/images-grid-skeleton.vue';
import SearchFilter from '../search-filter/search-filter.vue';
import Toolbar from '../toolbar/toolbar.vue';
import Pagination from '../pagination/pagination.vue';
import MapWithMarkers from '../map-with-markers/map-with-markers.vue';
import Rectangle from '../skeleton/rectangle.vue';
import FeaturedCategories from '../featured-categories/featured-categories.vue';
import getProductOrientation from '../../utils/getProductOrientation';
import config from '../../utils/config';

const createCacheKey = (id, zoom) => `${id}-${zoom}`;

const markersCache = {
  cached: {},
  has(id, zoom) {
    return has(this.cached, createCacheKey(id, zoom));
  },
  get(id, zoom) {
    return this.cached[createCacheKey(id, zoom)];
  },
  set(id, zoom, value) {
    this.cached[createCacheKey(id, zoom)] = value;
  },
};

export default {
  name: 'SearchResults',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    SearchFilter,
    ImagesGrid,
    ImagesGridSkeleton,
    Toolbar,
    Pagination,
    MapWithMarkers,
    Rectangle,
    FeaturedCategories,
  },
  mixins: [naviMixin, getRelLinksByRouteMixin],
  // TODO: Add default values for props
  /* eslint-disable vue/require-default-prop */
  props: {
    layout: {
      type: String,
      default: 'grid',
    },
    searchPhrase: {
      type: String,
    },
    page: {
      required: true,
      type: Number,
    },
    perPage: {
      type: Number,
    },
    panoramic: {
      type: Boolean,
    },
    orientation: {
      type: String,
    },
    premium: {
      type: Boolean,
    },
    types: {
      type: Array,
    },
    cities: {
      type: Array,
    },
    places: {
      type: Array,
    },
    seasons: {
      type: Array,
    },
    timesOfDay: {
      type: Array,
    },
    viewsOn: {
      type: Array,
    },
    viewsFrom: {
      type: Array,
    },
    lat: {
      type: Number,
    },
    lng: {
      type: Number,
    },
    radius: {
      type: Number,
    },
    zoom: {
      type: Number,
    },
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      link: this.$getRelLinksByRoute('search-results'),
    };
  },
  data() {
    return {
      loading: true,
      map: null,
      richMarkers: [], // References to Google Maps markers
      currentMaxZIndex: 1,
      anyMarkerActive: false,
      searchFiltersMobileActive: false,
      activeMobileFilter: null,
      currentSearchTermOld: {
        slug: '',
        name: '',
        count: 0,
      },
      searchFilterListOnClickOutside: {
        handler: () => {
          this.searchFiltersMobileActive = false;
        },
        middleware: ({ target }) => {
          const { searchFiltersLabel, searchFiltersLabelText } = this.$refs;
          return (
            target !== searchFiltersLabel && target !== searchFiltersLabelText
          );
        },
      },
    };
  },
  computed: {
    ...mapState('search', {
      products: 'products',
      typeTerms: 'type',
      cityTerms: 'city',
      placesTerms: 'place',
      seasonTerms: 'season',
      timeOfDayTerms: 'time-of-day',
      viewOnTerms: 'view-on',
      viewsOnTerms: 'view-on',
      viewsFromTerms: 'view-from',
      total: 'total',
      pages: 'pages',
    }),
    ...mapGetters('search', ['getTermName', 'getSearchTermBySlug']),
    metaTitle() {
      // Get all search terms
      const filterTerms = this.filters
        // Filter out inactive filters
        .filter(({ selectedTerms }) => selectedTerms.length > 0)
        // Get terms names based on their slugs
        .map(({ selectedTerms, taxonomy }) =>
          selectedTerms.map(term => {
            if (taxonomy === 'all') {
              return term;
            }

            return this.getTermName(taxonomy, term);
          })
        );

      if (!filterTerms.length) {
        return 'Szukaj miejsc, widoków, lokalizacji...';
      }

      const { page } = this.$route.params;
      const filtersPipeSeparated = filterTerms.join(' | ');

      if (!page) {
        return filtersPipeSeparated;
      }

      return `${filtersPipeSeparated} | strona ${page}`;
    },
    filters() {
      return [
        {
          showFilterUI: false,
          includeInQuery: false,
          enabled: true,
          hasSearch: false,
          terms: [
            {
              name: this.searchPhrase,
              slug: this.searchPhrase,
            },
          ],
          selectedTerms: this.searchPhrase === '' ? [] : [this.searchPhrase],
          taxonomy: 'all',
          name: 'Głowne hasło',
        },
        {
          showFilterUI: true,
          includeInQuery: true,
          enabled: true,
          hasSearch: true,
          terms: this.typeTerms,
          selectedTerms: this.types,
          taxonomy: 'type',
          name: 'Kategoria',
        },
        {
          showFilterUI: true,
          includeInQuery: true,
          enabled: this.isGridLayout,
          hasSearch: true,
          terms: this.cityTerms,
          selectedTerms: this.cities,
          taxonomy: 'city',
          name: 'Miejscowość',
        },
        {
          showFilterUI: true,
          includeInQuery: true,
          enabled: this.isGridLayout,
          hasSearch: true,
          terms: this.placesTerms,
          selectedTerms: this.places,
          taxonomy: 'place',
          name: 'Miejsce',
        },
        {
          showFilterUI: true,
          includeInQuery: true,
          enabled: true,
          hasSearch: false,
          terms: this.seasonTerms,
          selectedTerms: this.seasons,
          taxonomy: 'season',
          name: 'Pora roku',
        },
        {
          showFilterUI: true,
          includeInQuery: true,
          enabled: true,
          hasSearch: false,
          terms: this.timeOfDayTerms,
          selectedTerms: this.timesOfDay,
          taxonomy: 'time-of-day',
          name: 'Pora dnia',
        },
        {
          showFilterUI: true,
          includeInQuery: true,
          enabled: this.isGridLayout,
          hasSearch: true,
          terms: this.viewOnTerms,
          selectedTerms: this.viewsOn,
          taxonomy: 'view-on',
          name: 'Widok na',
        },
        {
          showFilterUI: true,
          includeInQuery: true,
          enabled: this.isGridLayout,
          hasSearch: true,
          terms: this.viewsFromTerms,
          selectedTerms: this.viewsFrom,
          taxonomy: 'view-from',
          name: 'Widok z',
        },
      ];
    },
    hasAtLeastOneFilter() {
      return (
        this.filters.filter(
          filter => filter.selectedTerms.length > 0 && filter.enabled
        ).length > 0
      );
    },
    // TODO: remove. replace with currentSearchTerm
    currentSearchTerm() {
      const term = this.getSearchTermBySlug(this.searchPhrase);

      /* eslint-disable vue/no-side-effects-in-computed-properties */
      // TODO: Fix!
      if (!term.slug) {
        return this.currentSearchTermOld;
      }

      this.currentSearchTermOld = term;
      return term;
    },
    // TODO: remove. replace with currentSearchTerm
    searchTermName() {
      return this.currentSearchTerm.name;
    },
    isMapLayout() {
      return this.layout === 'map';
    },
    isGridLayout() {
      return this.layout === 'grid';
    },
    mapLayoutHasPagination() {
      return this.pages > 1 && this.isMapLayout;
    },
    gridLayoutHasPagination() {
      return this.pages > 1 && this.isGridLayout;
    },
    center() {
      if (!this.lat || !this.lng) {
        return {
          lat: parseFloat(config('defaultLat')),
          lng: parseFloat(config('defaultLng')),
        };
      }

      return {
        lat: this.lat,
        lng: this.lng,
      };
    },
    markers() {
      if (this.isGridLayout) {
        return [];
      }

      // TODO: move to Vuex
      const isActive = id => {
        if (!this.anyMarkerActive) {
          return false;
        }

        if (!has(this.richMarkers, id)) {
          return false;
        }

        return this.currentMaxZIndex === this.richMarkers[id].getZIndex();
      };

      const markers = this.products.map(product => {
        const { id, slug } = product;
        const orientation = getProductOrientation(product);
        const lat = parseFloat(product.attributes.cords.lat);
        const lng = parseFloat(product.attributes.cords.lng);

        // TODO: product.attributes.image.tiny.small should never be undefined.
        // Fix it on the backend side
        return {
          id,
          slug,
          orientation,
          lat,
          lng,
          title: product.title.rendered,
          image: get(product, 'attributes.image.small.url') || '',
          isActive: isActive(id),
        };
      });

      // Group markers by latLng in order to find these ones that share the same cords
      const markersGroupedByLatLng = groupBy(
        markers,
        ({ lat, lng }) => `${lat}-${lng}`
      );

      // Randomize markers that share the same lat/lng
      return (
        map(markersGroupedByLatLng, markersGroup => {
          // Do not apply transformations to items that have unique lat/lng
          if (markersGroup.length === 1) {
            return markersGroup;
          }

          return markersGroup.map(marker => {
            const { id, lat, lng } = marker;
            return {
              ...marker,
              ...this.randomizeLatLng(id, lat, lng),
            };
          });
        })
          // Flatten the array
          .reduce((a, b) => a.concat(b), [])
      );
    },
  },
  watch: {
    $route: debounce(
      async function debounceFetchProducts() {
        this.loading = true;
        await this.fetchProducts();
        this.loading = false;
      },
      1000,
      {
        leading: true,
        trailing: true,
      }
    ),
  },
  async created() {
    this.loading = true;
    await this.fetchProducts();
    this.loading = false;

    this.fetchTermsIfNeeded();
  },
  methods: {
    ...mapActions('search', ['fetchTerms']),
    fetchProducts() {
      const enabledFilters = this.filters
        .filter(({ enabled }) => enabled)
        .map(({ taxonomy }) => camelCase(taxonomy));

      const layoutDependentFilters = pick(
        {
          type: this.types,
          city: this.cities,
          place: this.places,
          season: this.seasons,
          timeOfDay: this.timesOfDay,
          viewOn: this.viewsOn,
          viewsFrom: this.viewsFrom,
        },
        enabledFilters
      );

      const commonFilters = {
        panoramic: this.panoramic,
        orientation: this.orientation,
        premium: this.premium,
      };

      return this.$store.dispatch('search/fetchProducts', {
        page: this.page,
        perPage: this.perPage,
        taxonomies: {
          ...layoutDependentFilters,
          ...pick(commonFilters, ['panoramic', 'orientation']),
        },
        geoQuery: {
          lat: this.lat,
          lng: this.lng,
          radius: this.radius,
        },
        searchQuery: this.searchPhrase,
        premiumOnly: commonFilters.premium,
      });
    },
    toolbarOnChange(query) {
      this.$navi('search-results', {
        query,
      });
    },
    paginationOnChange(page) {
      if (page === this.page) {
        return;
      }

      this.$navi('search-results', {
        params: {
          page,
        },
      });
    },
    searchFilterOnInit(taxonomy) {
      this.fetchTerms({
        taxonomy,
      });
    },
    searchFilterOnChange(taxonomy, terms) {
      this.$navi('search-results', {
        query: {
          [taxonomy]: terms.join(','),
        },
      });
    },
    fetchTermsIfNeeded() {
      this.filters.forEach(({ taxonomy, selectedTerms }) => {
        if (selectedTerms.length > 0) {
          this.fetchTerms({
            taxonomy,
          });
        }
      });
    },
    removeFilter(taxonomy, term) {
      // If search phrase filter is deleted
      if (taxonomy === 'all') {
        this.$navi('search-results', {
          query: {
            searchPhrase: '',
          },
        });

        return;
      }

      // If "standard" filters are deleted
      const terms = this.filters
        .filter(filter => filter.taxonomy === taxonomy)
        .pop()
        .selectedTerms.filter(checkedTerm => checkedTerm !== term);

      this.$navi('search-results', {
        query: {
          [taxonomy]: terms.join(','),
        },
      });
    },
    randomizeLatLng(id, lat, lng) {
      if (markersCache.has(id, this.zoom)) {
        return markersCache.get(id, this.zoom);
      }

      const randomFloat = (min, max) => Math.random() * (max - min) + min;

      const earthRadius = 6378.137;
      const meterInDeg = 1 / (((2 * Math.PI) / 360) * earthRadius) / 1000;
      const metersPerPx =
        (156543.03392 * Math.cos((lat * Math.PI) / 180)) / 2 ** this.zoom;
      const randomModifier = randomFloat(0, metersPerPx * 100);
      const randomMetersPerPx = metersPerPx + randomModifier;
      const newLatitude = lat + randomMetersPerPx * meterInDeg;
      const newLongitude =
        lng +
        (randomMetersPerPx * meterInDeg) / Math.cos(lat * (Math.PI / 180));

      const newCords = {
        lat: newLatitude,
        lng: newLongitude,
      };

      markersCache.set(id, this.zoom, newCords);

      return newCords;
    },
    mapToggleOnClick() {
      this.$navi('search-results', {
        query: {
          layout: 'map',
        },
      });
    },
    gridToggleOnClick() {
      this.$navi('search-results', {
        query: {
          layout: 'grid',
          lat: false,
          lng: false,
          radius: false,
        },
      });
    },
    cordsOnUpdate({ lat, lng, radius, zoom }) {
      this.$navi(
        'search-results',
        {
          query: {
            lat,
            lng,
            radius,
            zoom,
          },
        },
        'replace'
      );
    },
    markerOnClick({ slug }) {
      this.$router.push({
        name: 'single-product',
        params: {
          slug,
        },
      });
    },
    markerOnAdd({ id, marker }) {
      this.richMarkers = {
        ...this.richMarkers,
        [id]: marker,
      };
    },
    markerOnDestroy(id) {
      delete this.richMarkers[id];
    },
    markerOnMouseover({ marker }) {
      this.anyMarkerActive = true;
      this.increaseZIndex(marker);
    },
    markerOnMouseout() {
      this.anyMarkerActive = false;
    },
    imageOnMouseEnter({ id }) {
      if (this.isGridLayout) {
        return;
      }

      this.anyMarkerActive = true;
      this.increaseZIndex(this.richMarkers[id]);
    },
    imageOnMouseLeave() {
      if (this.isGridLayout) {
        return;
      }

      this.anyMarkerActive = false;
    },
    filterLabelOnClick() {
      if (!this.$isMobile()) {
        return;
      }

      this.searchFiltersMobileActive = !this.searchFiltersMobileActive;
    },
    searchFilterLabelOnClick({ taxonomy, active }) {
      if (!active) {
        return;
      }

      this.activeMobileFilter = taxonomy;
    },
    increaseZIndex(marker) {
      const maxZIndex = this.$google.maps.Marker.MAX_ZINDEX;

      if (this.currentMaxZIndex >= maxZIndex) {
        const allMarkersIds = Object.keys(this.richMarkers);

        allMarkersIds.forEach(id => {
          this.richMarkers[id].setZIndex(1);
          this.currentMaxZIndex = 1;
        });
      }

      this.currentMaxZIndex += 1;
      marker.setZIndex(this.currentMaxZIndex);
    },
  },
};
</script>
