<template>
  <div class="loader">
    <div class="loader__item loader__item--1" />
    <div class="loader__item loader__item--2" />
    <div class="loader__item loader__item--3" />
    <div class="loader__item loader__item--4" />
    <div class="loader__item loader__item--5" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>
