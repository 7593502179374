<style scoped="true" lang="scss">
.map {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.15s;
  margin: 0 auto;

  &--loading {
    opacity: 0.85;
  }
}
</style>

<template>
  <div class="map">
    <slot v-if="map" />
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import mapProps from './mapProps';

export default {
  name: 'GoogleMap',
  provide() {
    return {
      getMap: this.getMap,
    };
  },
  props: {
    ...mapProps,
  },
  data: () => ({
    map: null,
  }),
  watch: {
    zoom() {
      this.updateMap();
    },
    center() {
      this.updateMap();
    },
    googleMapsOptions() {
      this.updateMap();
    },
  },
  mounted() {
    this.$loadGoogleMaps(google => {
      this.map = new google.maps.Map(this.$el, this.getMapSettings());

      // FIXME: it's causing Vue dev-tools performance problem
      // Let's uncomment only these events that we use in the application.

      // Standard Google Maps event
      const events = [
        // 'bounds_changed',
        // 'center_changed',
        // 'click',
        // 'dblclick',
        // 'drag',
        'dragend',
        // 'dragstart',
        // 'heading_changed',
        // 'idle',
        // 'maptypeid_changed',
        // 'mousemove',
        // 'mouseout',
        // 'mouseover',
        // 'projection_changed',
        // 'resize',
        // 'rightclick',
        // 'tilesloaded',
        // 'tilt_changed',
        'zoom_changed',
      ];

      const getCords = () => {
        const center = this.map.getCenter();

        return {
          lat: center.lat(),
          lng: center.lng(),
          radius: this.getRadius(),
          zoom: this.map.getZoom(),
        };
      };

      events.forEach(eventName => {
        this.map.addListener(eventName, () => {
          this.$emit(`map${upperFirst(camelCase(eventName))}`, {
            ...getCords(),
            map: this.map,
          });
        });
      });

      // Raise a custom event when the map is drawn
      this.$emit('mapDraw', this.map);
    });
  },
  methods: {
    getMapSettings() {
      return {
        ...this.googleMapsOptions,
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        center: this.center,
      };
    },
    updateMap() {
      this.map.setOptions(this.getMapSettings());
    },
    getMap() {
      return this.map;
    },
    getRadius() {
      const bounds = this.map.getBounds();
      const center = this.map.getCenter();
      const ne = bounds.getNorthEast();

      return (
        this.$google.maps.geometry.spherical.computeDistanceBetween(
          center,
          ne
        ) * 0.5
      );
    },
  },
};
</script>
