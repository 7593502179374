/**
 * @param {object} product
 * @return {string}
 */
export default product => {
  if (product.attributes.panoramic.slug === 'yes') {
    return 'landscape';
  }
  return product.attributes.orientation.slug; // 'landscape' || 'portrait'
};
