export default {
  methods: {
    $navi(name, { params = {}, query = {} }, method = 'push') {
      const methods = ['push', 'replace', 'resolve'];

      if (!methods.includes(method)) {
        throw new Error(`${method} method is not allowed`);
      }

      return this.$router[method]({
        name,
        params: {
          ...this.$route.params,
          page: 1,
          ...params,
        },
        query: { ...this.$route.query, ...query },
      });
    },
  },
};
