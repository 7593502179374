<template>
  <div
    :class="{
      'search-filter--active': mobileActive,
    }"
    class="search-filter"
    @mouseenter="hover = true"
    @mouseenter.once="onMouseEnter"
    @mouseleave="hover = false"
  >
    <label :for="nameAttr" class="search-filter__name" @click="labelOnClick">
      {{ name }}
    </label>

    <div class="search-filter__dropdown">
      <input
        v-if="hasSearch"
        :id="nameAttr"
        v-model="filter"
        class="search-filter__input"
        type="text"
      />

      <ul v-if="filteredTerms.length > 0" class="search-filter__list">
        <li
          v-for="term in filteredTerms"
          :key="term.id"
          class="search-filter__item"
        >
          <!-- TODO: use <Toggle> -->
          <div class="toggle-checkbox">
            <input
              :id="term.id"
              :checked="isChecked(term.slug)"
              :name="term.taxonomy"
              class="toggle-checkbox__input"
              type="checkbox"
              @change="toggleTerm(term.slug)"
            />
            <label
              :title="term.name"
              :for="term.id"
              class="toggle-checkbox__label toggle-checkbox__label--up-to-xxl-white styled-checkbox__label--large"
            >
              {{ term.name }}
            </label>
          </div>
        </li>
      </ul>

      <p
        v-if="filteredTerms.length === 0 && filter.length > 0"
        class="search-filter__no-results"
      >
        Brak wyników dla: <strong>{{ filter }}</strong>
      </p>

      <p v-if="terms.length === 0" class="search-filter__loading">
        <Loader />
      </p>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import kebabCase from 'lodash/kebabCase';
import Loader from '../loader/loader.vue';

export default {
  name: 'SearchFilter',
  components: {
    Loader,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: false,
      required: false,
    },
    taxonomy: {
      type: String,
      required: true,
    },
    terms: {
      type: Array,
      required: true,
    },
    checked: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isMobileActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      filter: '',
      hover: false,
      termsListClone: [],
      mobileActive: false,
    };
  },
  computed: {
    nameAttr() {
      return `${kebabCase(this.name)}-${Math.random()}`;
    },
    termsList() {
      if (this.hover && this.termsListClone.length > 0) {
        return this.termsListClone;
      }

      const terms = this.terms.map(term => {
        if (!this.isChecked(term.slug)) {
          return term;
        }

        return { ...term, checked: true };
      });

      const termsOrdered = orderBy(terms, ['checked', 'name'], ['desc', 'asc']);

      // TODO: FIX
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      this.termsListClone = termsOrdered;

      return termsOrdered;
    },
    filteredTerms() {
      if (this.filter === '') {
        return this.termsList;
      }

      return this.termsList.filter(
        term => term.name.toLowerCase().indexOf(this.filter) !== -1
      );
    },
    checkedComputed: {
      get() {
        return this.checked;
      },
      set(termSlug) {
        let checkedSlugs = this.checked;

        if (checkedSlugs.indexOf(termSlug) === -1) {
          checkedSlugs.push(termSlug);
        } else {
          checkedSlugs = checkedSlugs.filter(item => item !== termSlug);
        }

        this.$emit('change', this.taxonomy, checkedSlugs);
      },
    },
    checkedTerms() {
      return this.terms.filter(
        term => this.checkedComputed.indexOf(term.slug) !== -1
      );
    },
  },
  watch: {
    hover(hover) {
      if (hover) {
        return;
      }
      this.filter = '';
    },
    isMobileActive(isMobileActive) {
      if (!this.$isMobile()) {
        return;
      }

      this.mobileActive = isMobileActive;
    },
  },
  created() {
    this.mobileActive = this.isMobileActive;
  },
  methods: {
    isChecked(termSlug) {
      return this.checked.indexOf(termSlug) !== -1;
    },
    onMouseEnter() {
      this.$emit('init', this.taxonomy);
    },
    toggleTerm(termSlug) {
      this.checkedComputed = termSlug;
    },
    labelOnClick() {
      if (!this.$isMobile()) {
        return;
      }

      this.mobileActive = !this.mobileActive;
      this.$emit('label-click', {
        taxonomy: this.taxonomy,
        active: this.mobileActive,
      });
    },
  },
};
</script>
