var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"search-results",class:{
    'search-results--grid-layout': _vm.isGridLayout,
    'search-results--map-layout': _vm.isMapLayout,
    'search-results--has-buttons': _vm.hasAtLeastOneFilter,
    'search-results--grid-layout-has-pagination': _vm.gridLayoutHasPagination,
    'search-results--map-layout-has-pagination': _vm.mapLayoutHasPagination,
  }},[_c('div',{staticClass:"search-results__menu search-results__menu--filters"},[_c('div',{staticClass:"search-results__filters"},[_c('div',{staticClass:"search-filters",class:{
          'search-filters--active': _vm.searchFiltersMobileActive,
        }},[_c('p',{ref:"searchFiltersLabel",staticClass:"search-filters__label",on:{"click":_vm.filterLabelOnClick}},[_vm._v(" Filtruj "),_c('span',{ref:"searchFiltersLabelText",staticClass:"search-filters__text-xs-hidden"},[_vm._v(" wyszukiwanie ")]),_c('span',{staticClass:"search-filters__colon"},[_vm._v(":")])]),_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.searchFilterListOnClickOutside),expression:"searchFilterListOnClickOutside"}],staticClass:"search-filters__list"},_vm._l((_vm.filters),function(filter){return _c('li',{key:filter.name,staticClass:"search-filters__item"},[(filter.enabled && filter.showFilterUI)?_c('SearchFilter',{attrs:{"checked":filter.selectedTerms,"has-search":filter.hasSearch,"terms":filter.terms,"taxonomy":filter.taxonomy,"name":filter.name,"is-mobile-active":filter.taxonomy === _vm.activeMobileFilter},on:{"init":_vm.searchFilterOnInit,"change":_vm.searchFilterOnChange,"label-click":_vm.searchFilterLabelOnClick}}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"search-results__buttons"},[_c('div',{staticClass:"layout-toggle"},[_c('p',{staticClass:"layout-toggle__label"},[_vm._v(" Sposób wyświetlania : ")]),_c('button',{staticClass:"layout-toggle__button layout-toggle__button--map",class:{
            'layout-toggle__button--active': _vm.isMapLayout,
          },on:{"click":_vm.mapToggleOnClick}},[_vm._v(" Mapa ")]),_c('button',{staticClass:"layout-toggle__button layout-toggle__button--grid",class:{
            'layout-toggle__button--active': _vm.isGridLayout,
          },on:{"click":_vm.gridToggleOnClick}},[_vm._v(" Lista ")])])])]),(_vm.hasAtLeastOneFilter)?_c('div',{staticClass:"search-results__menu search-results__menu--buttons"},[_c('ul',{staticClass:"active-filters"},[_vm._l((_vm.filters),function(ref){
          var selectedTerms = ref.selectedTerms;
          var name = ref.name;
          var taxonomy = ref.taxonomy;
          var enabled = ref.enabled;
return [(enabled)?_vm._l((selectedTerms),function(term){return _c('li',{key:(taxonomy + "-" + term),staticClass:"active-filters__item"},[_c('button',{staticClass:"active-filters__delete",attrs:{"aria-label":"Usuń"},on:{"click":function($event){return _vm.removeFilter(taxonomy, term)}}},[_vm._v(" × ")]),_c('span',{staticClass:"active-filters__category"},[_vm._v(" "+_vm._s(name)+": ")]),_c('span',{staticClass:"active-filters__name"},[(taxonomy === 'all')?[_vm._v(" "+_vm._s(_vm.$route.query.searchPhrase)+" ")]:[_vm._v(" "+_vm._s(_vm.getTermName(taxonomy, term))+" ")]],2)])}):_vm._e()]})],2)]):_vm._e(),_c('div',{staticClass:"search-results__container"},[(_vm.isMapLayout)?_c('div',{staticClass:"search-results__map"},[_c('MapWithMarkers',{attrs:{"markers":_vm.markers,"zoom":_vm.zoom,"min-zoom":10,"max-zoom":18,"center":_vm.center},on:{"mapDragend":_vm.cordsOnUpdate,"mapZoomChanged":_vm.cordsOnUpdate,"markerAdded":_vm.markerOnAdd,"markerDestroyed":_vm.markerOnDestroy,"markerClick":_vm.markerOnClick,"markerMouseover":_vm.markerOnMouseover,"markerMouseout":_vm.markerOnMouseout}})],1):_vm._e(),_c('section',{staticClass:"search-results__content",class:{
        'search-results__content--loading': _vm.loading,
      }},[_c('header',{staticClass:"search-results__header"},[(_vm.total)?_c('h1',{staticClass:"search-results__count"},[_vm._v(" Znaleziono "),_c('strong',[_vm._v(_vm._s(_vm.total))]),_vm._v(" zdjęć ")]):_vm._e(),_c('div',{staticClass:"search-results__toolbar"},[_c('Toolbar',{attrs:{"orientation":_vm.orientation,"panoramic":_vm.panoramic,"premium":_vm.premium,"per-page":_vm.perPage},on:{"change":_vm.toolbarOnChange}})],1)]),_c('div',{staticClass:"search-results__images-grid",class:{
          'search-results__images-grid--empty':
            _vm.products.length === 0 && !_vm.loading,
        }},[(_vm.products.length > 0 && !_vm.loading)?_c('ImagesGrid',{attrs:{"images":_vm.products,"fixed-layout":_vm.isGridLayout,"compact-mode":_vm.isMapLayout},on:{"imageMouseEnter":_vm.imageOnMouseEnter,"imageMouseLeave":_vm.imageOnMouseLeave}}):_vm._e(),(_vm.loading)?_c('ImagesGridSkeleton',{attrs:{"fixed-layout":_vm.isGridLayout}}):_vm._e()],1),(_vm.mapLayoutHasPagination)?_c('div',{staticClass:"search-results__pagination"},[_c('Pagination',{attrs:{"current":_vm.page,"total":_vm.total,"pages":_vm.pages,"loading":_vm.loading},on:{"change":_vm.paginationOnChange}})],1):_vm._e(),(_vm.products.length === 0 && !_vm.loading)?_c('div',{staticClass:"no-results"},[_c('div',{staticClass:"info-box",class:{
            'info-box--simple': _vm.isGridLayout,
          }},[_c('p',{staticClass:"info-box__text"},[_vm._v(" Brak wyników pasujących do wybranych filtrów. ")]),_c('div',{staticClass:"info-box__button"},[_c('router-link',{staticClass:"button button--strong",attrs:{"to":{
                name: 'search-results',
                query: {
                  layout: _vm.layout,
                },
              }}},[(_vm.searchTermName)?[_vm._v(" Wróć do wyników wyszukiwania dla frazy: "),_c('strong',[_vm._v(_vm._s(_vm.searchTermName))])]:[_vm._v(" Wyczyść wszystkie filtry ")]],2)],1)]),(_vm.isGridLayout)?_c('div',{staticClass:"no-results__categories"},[_c('h2',{staticClass:"text text--xs-header-3 text--sm-header-2 text--xs-center text--sm-strong text--sm-mt-1 text--sm-mb-2"},[_vm._v(" Polecane kategorie ")]),_c('FeaturedCategories')],1):_vm._e()]):_vm._e()])]),(_vm.gridLayoutHasPagination)?_c('div',{staticClass:"search-results__pagination"},[_c('Pagination',{attrs:{"current":_vm.page,"total":_vm.total,"pages":_vm.pages},on:{"change":_vm.paginationOnChange}})],1):_vm._e(),(_vm.isGridLayout && _vm.loading)?_c('div',{staticClass:"search-results__pagination"},[_c('div',{staticClass:"container"},[_c('Rectangle',{attrs:{"height":2}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }