export default {
  methods: {
    $getRelLinksByRoute(routeName) {
      return [
        {
          rel: 'next',
          resolvedRoute: this.$navi(
            routeName,
            {
              params: {
                page: this.page + 1,
              },
            },
            'resolve'
          ),
        },
        {
          rel: 'prev',
          resolvedRoute: this.$navi(
            routeName,
            {
              params: {
                page: this.page - 1,
              },
            },
            'resolve'
          ),
        },
      ]
        .filter(({ resolvedRoute }) => resolvedRoute.route.params.page > 0)
        .map(({ resolvedRoute, rel }) => ({
          rel,
          href: resolvedRoute.href,
        }));
    },
  },
};
