<template>
  <ul class="category-toolbar">
    <li class="category-toolbar__item">
      <Toggle
        id="premium"
        v-model="premiumComputed"
        type="checkbox"
        name="premium"
      >
        Pokaż tylko premium
      </Toggle>
    </li>

    <li class="category-toolbar__item">
      <div class="orientation">
        <span class="orientation__fake-label">
          Orientacja zdjęcia:
        </span>

        <input
          id="orientation-any"
          v-model="orientationAndPanoramic"
          :value="{
            orientation: 'any',
            panoramic: false,
          }"
          class="orientation__input"
          type="radio"
          name="orientation"
        />

        <label
          class="orientation__label orientation__label--any"
          for="orientation-any"
        >
          <span class="orientation__tooltip">
            Dowolna
          </span>
        </label>

        <input
          id="orientation-portrait"
          v-model="orientationAndPanoramic"
          :value="{
            orientation: 'portrait',
            panoramic: false,
          }"
          class="orientation__input"
          type="radio"
          name="orientation"
        />
        <label
          class="orientation__label orientation__label--portrait"
          for="orientation-portrait"
        >
          <span class="orientation__tooltip">
            Pionowa
          </span>
        </label>

        <input
          id="orientation-landscape"
          v-model="orientationAndPanoramic"
          :value="{
            orientation: 'landscape',
            panoramic: false,
          }"
          class="orientation__input"
          type="radio"
          name="orientation"
        />
        <label
          class="orientation__label orientation__label--landscape"
          for="orientation-landscape"
        >
          <span class="orientation__tooltip">
            Pozioma
          </span>
        </label>

        <input
          id="orientation-panorama"
          v-model="orientationAndPanoramic"
          :value="{
            orientation: 'landscape',
            panoramic: true,
          }"
          class="orientation__input"
          type="radio"
          name="orientation"
        />
        <label
          class="orientation__label orientation__label--panorama"
          for="orientation-panorama"
        >
          <span class="orientation__tooltip">
            Panorama
          </span>
        </label>
      </div>
    </li>

    <li class="category-toolbar__item">
      <label for="per-page" class="sr-only">
        Zdjęć na stronie
      </label>
      <select
        id="per-page"
        v-model.number="perPageComputed"
        class="toolbar-select"
      >
        <option value="20">
          20
        </option>
        <option value="50">
          50
        </option>
        <option value="100">
          100
        </option>
      </select>
    </li>
  </ul>
</template>

<script>
import mapProps from '../../utils/mapProps';
import Toggle from '../toggle/toggle.vue';

export default {
  name: 'Toolbar',
  components: {
    Toggle,
  },
  props: {
    orientation: {
      required: true,
      type: String,
    },
    panoramic: {
      required: true,
      type: Boolean,
    },
    premium: {
      required: true,
      type: Boolean,
    },
    perPage: {
      required: true,
      type: Number,
    },
  },
  computed: {
    orientationAndPanoramic: {
      get() {
        return {
          orientation: this.orientation,
          panoramic: this.panoramic,
        };
      },
      set({ orientation, panoramic }) {
        this.$emit('change', {
          orientation,
          panoramic,
        });
      },
    },
    ...mapProps('Computed', ['perPage', 'premium'], function callback(
      prop,
      value
    ) {
      this.$emit('change', {
        [prop]: value,
      });
    }),
  },
};
</script>
