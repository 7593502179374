import has from 'lodash/has';
import isNumber from 'lodash/isNumber';

export default {
  minZoom: {
    type: Number,
    required: true,
    default: 8,
    validate(minZoom) {
      return minZoom <= this.zoom && minZoom < this.maxZoom;
    },
  },
  zoom: {
    type: Number,
    required: false,
    default: 8,
    validate(zoom) {
      return zoom >= this.minZoom && zoom <= this.maxZoom;
    },
  },
  maxZoom: {
    type: Number,
    required: true,
    default: 15,
    validate(maxZoom) {
      return maxZoom >= this.zoom && maxZoom >= this.minZoom;
    },
  },
  center: {
    type: Object,
    required: true,
    validator(center) {
      return (
        has(center, 'lat') &&
        has(center, 'lng') &&
        isNumber(center.lat) &&
        isNumber(center.lng)
      );
    },
  },
  googleMapsOptions: {
    type: Object,
    required: false,
    default() {
      return {};
    },
  },
};
