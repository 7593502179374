var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GoogleMap',_vm._g({attrs:{"zoom":_vm.zoom,"min-zoom":10,"max-zoom":20,"center":_vm.center}},_vm.$listeners),_vm._l((_vm.markers),function(ref){
    var lat = ref.lat;
    var lng = ref.lng;
    var title = ref.title;
    var id = ref.id;
    var slug = ref.slug;
    var image = ref.image;
    var isActive = ref.isActive;
    var orientation = ref.orientation;
return _c('GoogleMarker',_vm._g({key:id,attrs:{"id":id,"lat":lat,"lng":lng,"title":title,"slug":slug,"image":image,"is-active":isActive,"orientation":orientation}},_vm.$listeners))}),1)}
var staticRenderFns = []

export { render, staticRenderFns }