<template>
  <div class="pagination">
    <div
      :class="{
        'pagination__button--hidden': !hasPrevious,
        'pagination__button--loading': loading,
      }"
      class="pagination__button pagination__button--prev"
      @click="prevOnClick"
    >
      <div class="button button--up-to-md-small button--full button--alt">
        <span class="pagination__button-text">Poprzednia</span>
      </div>
    </div>

    <input
      v-model="currentComputed"
      :max="pages"
      class="pagination__current"
      type="number"
      min="1"
    />

    <span class="pagination__separator">
      z
    </span>

    <span class="pagination__total">
      {{ pages }}
    </span>

    <div
      :class="{
        'pagination__button--hidden': !hasNext,
        'pagination__button--loading': loading,
      }"
      class="pagination__button pagination__button--next"
      @click="nextOnClick"
    >
      <div class="button button--up-to-md-small button--full button--alt">
        <span class="pagination__button-text">Następna</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    currentComputed: {
      get() {
        return this.current;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    hasPrevious() {
      return this.currentComputed > 1;
    },
    hasNext() {
      return this.currentComputed < this.pages;
    },
  },
  watch: {
    currentComputed() {
      this.$emit('change', this.currentComputed);
    },
  },
  mounted() {
    this.currentComputed = this.current;
  },
  methods: {
    prevOnClick() {
      if (!this.hasPrevious) {
        return;
      }

      this.currentComputed -= 1;
    },
    nextOnClick() {
      if (!this.hasNext) {
        return;
      }

      this.currentComputed += 1;
    },
  },
};
</script>
