export default (namespace = 'Computed', props = [], callback) =>
  props.reduce((obj, prop) => {
    /* eslint-disable no-param-reassign */
    const propName = `${prop}${namespace}`;
    obj[propName] = {
      get() {
        return this[prop];
      },
      set(value) {
        callback.call(this, prop, value);
      },
    };
    return obj;
  }, {});
